<template>
  <BlokkliProvider v-bind="blokkliProps" :entity="props">
    <div
      :class="{
        '-mt-22': hero && !isMarketing,
      }"
    >
      <NodePageHero v-if="hero" v-bind="props" is-landing />
      <div v-if="lead && !isMarketingHero" class="grid-container">
        <div class="grid-container-8 pb-10">
          <div
            v-blokkli-editable:field_lead="{ type: 'frame' }"
            class="text-xl md:text-2xl"
            v-html="lead"
          />
        </div>
      </div>
      <div>
        <BlokkliField :list="paragraphs" name="field_paragraphs" />
      </div>
    </div>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  lead?: string
  isMarketingHero?: NodePageFragment['isMarketingHero']
  heroLink?: NodePageFragment['heroLink']
  businessArea?: NodePageFragment['businessArea']
  hero?: NodePageFragment['hero']
  paragraphs?: NodePageFragment['paragraphs']
  canEdit?: NodePageFragment['canEdit']
  blokkliProps: NodePageFragment['blokkliProps']
}>()

const initData = await useInitData()
const isMarketing = computed(() => {
  return initData.value?.siteInfo.domain !== 'sv'
})

// Provide the business area to views
provide('businessArea', '' + props.businessArea?.id)
</script>
